import React, { useRef } from "react";
import "./Join.css";
import emailjs from "@emailjs/browser";
const Join = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm("service_j8aftad", "template_77yoaeh", form.current, {
        publicKey: "1KpzxjpoE_4SXWHcH",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };
  return (
    <>
      <div className="join" id="join">
        <div className="left-j">
          <hr />
          <div>
            <span className="stroke-text">READY TO </span>
            <span>LEVEL UP </span>
          </div>
          <div>
            <span>YOUR BODY </span>
            <span className="stroke-text">WITH US?</span>
          </div>
        </div>
        <div className="right-j">
          <form ref={form} className="email-container">
            <input
              type="email"
              placeholder="Enter your email address"
              name="user_email" onSubmit={sendEmail}
            />
            <button className="btn btn-j">Join Now</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Join;
