import './App.css';
import Footer from './component/Footer/Footer';
import Hero from './component/Hero/Hero';
import Join from './component/Join/Join';
import Plan from './component/Plan/Plan';
import Programs from './component/Programs/Programs';
import Reasons from './component/Reasons/Reasons';
import Testimonial from './component/Testimonial/Testimonial';
function App() {
  return (
    <div className="App">
      <Hero />
      <Programs />
      <Reasons />
      <Plan />
      <Testimonial />
      <Join />
      <Footer />
    </div>
  );
}

export default App;
