import React from "react";
import "./Reasons.css";
import image1 from "../../assets/image1.png";
import image2 from "../../assets/image2.png";
import image3 from "../../assets/image3.png";
import image4 from "../../assets/image4.png";
import Nike from "../../assets/nike.png";
import Tick from "../../assets/tick.png";
import Adidas from "../../assets/adidas.png";
import Nb from "../../assets/nb.png";
const Reasons = () => {
  return (
    <>
      <div className="reasons" id="reasons">
        <div className="left-r">
          <img src={image1} alt="" />
          <img src={image2} alt="" />
          <img src={image3} alt="" />
          <img src={image4} alt="" />
        </div>
        <div className="right-r">
          <span>Some reasons</span>
          <div>
            <span className="stroke-text">why</span>
            <span> Choose</span>
          </div>
          <div className="details-r">
            <div>
              <img src={Tick} alt=""></img>
              <span>OVER 140+ EXPERT COACHS</span>
            </div>
            <div>
              <img src={Tick} alt="" />
              <span>TRAIN SMARTER AND FASTER THAN BEFORE</span>
            </div>
            <div>
              <img src={Tick} alt="" />
              <span>1 FREE PROGRAM FOR NEW MEMBER</span>
            </div>
            <div>
              <img src={Tick} alt="" />
              <span>RELIABLE PARTNERS</span>
            </div>
          </div>
          <span
            style={{
              color: "var(--gray)",
              fontWeight : "normal"
            }}
          >
            OUR PARTNERS
          </span>
          <div className="partner">
            <img src={Nb} alt="" />
            <img src={Adidas} alt="" />
            <img src={Nike} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Reasons;
