import React, { useState } from "react";
import "./Testimonial.css";
import testimonialData from "../../data/testimonialsData";
import LeftArrow from "../../assets/leftArrow.png";
import RightArrow from "../../assets/rightArrow.png";
import { motion } from "framer-motion";
const Testimonial = () => {
  const [selected, setSelected] = useState(0);
  const tLength = testimonialData.length;
  const transition = { type: "string", duration: 3 };

  return (
    <>
      <div className="Testimonial" id="testimonials">
        <div className="left-t">
          <span>Testimonials</span>
          <span className="stroke-text">What they</span>
          <span>say about us</span>
          <motion.span
            key={selected}
            initial={{ opacity: 1, x: -100 }}
            transition={{ ...transition, duration: 2 }}
            whileInView={{ opacity: 1, x: 0 }}
          >
            {testimonialData[selected].review}
          </motion.span>
          <span>
            <span style={{ color: "var(--orange)" }}>
              {testimonialData[selected].name}{" "}
            </span>
            - {testimonialData[selected].status}
          </span>
        </div>
        <div className="right-t">
          <motion.div
            initial={{ opacity: 1, x: -100 }}
            transition={{ ...transition, duration: 2 }}
            whileInView={{ opacity: 1, x: 0 }}
          ></motion.div>
          <motion.div
            initial={{ opacity: 1, x: 100 }}
            transition={{ ...transition, duration: 2 }}
            whileInView={{ opacity: 1, x: 0 }}
          ></motion.div>
          <motion.img
            key={selected}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ ...transition, duration: 1.5 }}
            src={testimonialData[selected].image}
            alt=""
          />
          <div className="arrows">
            <img
              onClick={() => {
                selected === 0
                  ? setSelected(tLength - 1)
                  : setSelected((prev) => prev - 1);
              }}
              src={LeftArrow}
              alt=""
            />
            <img
              onClick={() => {
                selected === tLength - 1
                  ? setSelected(0)
                  : setSelected((prev) => prev + 1);
              }}
              src={RightArrow}
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
